import { render, staticRenderFns } from "./TestHistory.vue?vue&type=template&id=2b20e3d0&scoped=true&"
import script from "./TestHistory.vue?vue&type=script&lang=js&"
export * from "./TestHistory.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b20e3d0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
import { VTimeline } from 'vuetify/lib/components/VTimeline';
import { VTimelineItem } from 'vuetify/lib/components/VTimeline';
installComponents(component, {VCard,VCardText,VCardTitle,VFlex,VForm,VTextarea,VTimeline,VTimelineItem})
